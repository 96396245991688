body {
  margin: 0px;
  padding: 0px;
}

#universal-sidebar {
  display: block;
  width: 100vw;
  height: 100vh;
  padding: 0px;
  margin: 0px;  
  border-style: none;
}