body {
  margin: 0;
  padding: 0;
}

#universal-sidebar {
  border-style: none;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: block;
}

/*# sourceMappingURL=index.c6a8e6ae.css.map */
